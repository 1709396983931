import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal" id="about-us">
            <div className="container">
                <div className="row align-items-center">
                {/* <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/team/team-member-1.jpg"} alt="thumbnail" />
                    </div>
                    </div>
                </div> */}
                <div className="col-lg-12">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">About Us</span>
                        <h2>Punit Kaur</h2>  
                        <p>Passionate and energetic programmer seeking assignments in Java Microservices Backend Application Development. Expertise in Java 8. Well-versed in Core Java and Frameworks. Have good experience and understanding in Spring MVC, Spring Boot, Spring Data, Spring Cloud, Microservices, Pivotal Cloud Foundry with Maven build tool and data processing technologies. I have also built applications from scratch and deployed using CI/CD using version control system like GIT, using Jenkins for CI/CD and containerising it with Docker. Have knowledge and hands-on on Pivotal Cloud Foundry to deploy applications on Cloud.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;