import React from "react";
import { HashLink } from "react-router-hash-link";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          <HashLink smooth to="#our-services">Our Services</HashLink>
        </li>
        <li className="menu-item-has-children">
          <HashLink smooth to="#about-us">About Us</HashLink>
        </li>
        <li className="menu-item-has-children">
          <HashLink smooth to="#testimonials">Testimonials</HashLink>
        </li>
        <li className="menu-item-has-children">
          <HashLink smooth to="#contact-us">Contact Us</HashLink>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
