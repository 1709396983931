import React from 'react';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutFive from '../component/about/AboutFive';
import BannerOne from '../component/banner/BannerOne';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CounterUpOne from '../component/counterup/CounterUpOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import PricingOne from '../component/pricing/PricingOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import FormThree from "../component/contact/FormThree";


const DigitalAgency = () => {

    return (
        <>
        <SEO title="Supreme Soft Inc."/>
        <main className="main-wrapper">
            <HeaderOne />
            <BannerOne />
            <div className="section section-padding" id="our-services">
                <div className="container">
                <SectionTitle 
                    subtitle="What We Can Do For You"
                    title="Services we can <br> help you with"
                    description=""
                    textAlignment="heading-left mb--20 mb_md--70"
                    textColor=""
                />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                    </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>

            </div>
            <AboutFive />
            <TestimonialOne />
            <div className="section-padding bg-color-dark" id='contact-us'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-address mb--30">
                            <SectionTitle 
                                subtitle="Need our services?"
                                title="Let’s work together"
                                description=""
                                textAlignment="heading-light-left"
                                textColor=""
                            />
                            <div className="address-list">
                                <div className="address">
                                <h6 className="title">Mail</h6>
                                <p>punitkaursiddhu123@yahoo.com</p>
                                </div>
                                <div className="address">
                                <h6 className="title">Address</h6>
                                <p>Vancouver, BC</p>
                                </div>
                                <div className="address">
                                <h6 className="title">Phone</h6>
                                <p>+1-778-855-3435</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-form-box mb--30">
                                <h3 className="title">Describe your requirements</h3>
                                <FormThree />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <FooterTwo parentClass="" />
        </main>
        </>
    )
}

export default DigitalAgency;

